// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '~@angular/material' as mat;
@use 'sass:map';
// Plus imports for other components in your app.

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();
@import './palette.scss';

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/

// You can use predefined material palletes:

// $primary-color: mat.$cyan-palette;
// $accent-color: mat.$amber-palette;

// Or custom ones (imported from palette.scss)

$primary-color: $blue-palette;
$accent-color: $orange-palette;

$app-primary-light: mat.define-palette($primary-color, 500, 300, 700);
$app-accent-light: mat.define-palette($accent-color, 500, 300, A700);
$app-primary-dark: mat.define-palette($primary-color, 700, 500, 900);
$app-accent-dark: mat.define-palette($accent-color, 700, 500, 900);
$app-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$app-light-theme: mat.define-light-theme(
	(
		color: (
			primary: $app-primary-light,
			accent: $app-accent-light,
			warn: $app-warn,
		),
	)
);

$app-dark-theme: mat.define-dark-theme(
	(
		color: (
			primary: $app-primary-dark,
			accent: $app-accent-dark,
			warn: $app-warn,
		),
	)
);

@mixin setColors($theme) {
	$color-config: mat.get-color-config($theme);
	$primaryPalette: map.get($color-config, 'primary');
	$accentPalette: map.get($color-config, 'accent');
	$foreground: map.get($color-config, 'foreground');
	$background: map.get($color-config, 'background');
	$primary: mat.get-color-from-palette($primaryPalette);
	$primary-lighter: mat.get-color-from-palette($primaryPalette, 'lighter');
	$primary-darker: mat.get-color-from-palette($primaryPalette, 'darker');
	$accent: mat.get-color-from-palette($accentPalette);
	$accent-lighter: mat.get-color-from-palette($accentPalette, 'lighter');
	$accent-darker: mat.get-color-from-palette($accentPalette, 'darket');
	$backgroundColor: mat.get-color-from-palette($background, 'background');
	$appBar: mat.get-color-from-palette($background, 'app-bar');
	$hover: mat.get-color-from-palette($background, 'hover');
	$disabled: mat.get-color-from-palette($foreground, 'disabled');
	$text: mat.get-color-from-palette($foreground, 'text');
	$secondaryText: mat.get-color-from-palette($foreground, 'secondary-text');
	$primaryContrast: mat.get-color-from-palette($primaryPalette, 'default-contrast');

	// status-bar: black,
	// app-bar:    map_get($mat-grey, 900),
	// background: #303030,
	// hover:      rgba(white, 0.04), // TODO(kara): check style with Material Design UX
	// card:       map_get($mat-grey, 800),
	// dialog:     map_get($mat-grey, 800),
	// disabled-button: $white-12-opacity,
	// raised-button: map-get($mat-grey, 800),
	// focused-button: $white-6-opacity,
	// selected-button: map_get($mat-grey, 900),
	// selected-disabled-button: map_get($mat-grey, 800),
	// disabled-button-toggle: map_get($mat-grey, 1000),

	// https://stackoverflow.com/a/45028242/8793377
	:root {
		--hover: #{$hover};
		--background: #{$backgroundColor};
		--app-bar: #{$appBar};
		--disabled: #{$disabled};
		--text: #{$text};
		--secondary-text: #{$secondaryText};
		--primary-contrast: #{$primaryContrast};
		--success: #4caf50;
		--success-light: #cdf2ce;
		--warn: #ff9800;
		--warn-light: #ffefc7;
		--error: #f44336;
		--error-light: #ffd6d3;
		--primary: #{$primary};
		--primary-lighter: #{$primary-lighter};
		--primary-darker: #{$primary-darker};
		--accent: #{$accent};
		--accent-lighter: #{$accent-lighter};
		--accent-darker: #{$accent-darker};
	}
}

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.

@media (prefers-color-scheme: light) {
	@include mat.all-component-themes($app-light-theme);
	@include setColors($app-light-theme);
}

@media (prefers-color-scheme: dark) {
	@include mat.all-component-colors($app-light-theme);
	@include setColors($app-light-theme);
}
